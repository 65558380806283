import { createApp } from "vue";
import App from "./App.vue";
import router from './router';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'nifty-style'
import '@/styles/index.scss'
import { initWebSocket } from '@/utils/globalSocket';
import locale from 'element-plus/es/locale/lang/zh-cn'


import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import '@/assets/css/vxe-table.scss'
VXETable.config({
	table: {
		minHeight: 90
	}
})
import ExcelJS from 'exceljs'
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
VXETable.use(VXETablePluginExportXLSX, {
	ExcelJS
})
// import {setDomFontSizeDebounce} from '@/utils/setRem'
// setDomFontSizeDebounce()
// const userInfo = JSON.parse(localStorage.getItem("userInfo"));
// if(userInfo && userInfo.token) initWebSocket()

createApp(App).use(router).use(ElementPlus, { locale }).use(VXETable).mount("#app");
import "./registerConfig"; // 引入qiankun主应用
