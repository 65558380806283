import { getCurrentInstance } from 'vue'
// const { proxy } = getCurrentInstance()

// 加载js
export const loadScript = (src) => {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script')
		script.src = src
		script.async = true
		script.onload = resolve
		script.onerror = reject
		document.body.appendChild(script)
	})
}



export let mClientAll = null;
const GATEWAY_URL = 'https://v.zgzhmz.com:7715'
export let ORGIIN = '';
export const mClientToken = sessionStorage.getItem('mClientToken')

export const loadmClient = async() => {
	await loadScript('https://mx.zgzhmz.com/mcs8Client.js')
	await init(GATEWAY_URL)
}

/**
 * 监理仪网关连接
 * @param {*} gatewayUrl 网关地址
 * @param {*} localVideo 本地Video元素
 * @param {*} localAudio 本地Audio元素
 */
const init = (gatewayUrl) => {
	let url = new URL(gatewayUrl)

	let httpProxy = null
	if (url.port == '') {
		httpProxy = `:443/gateway`
	} else {
		httpProxy = `:${url.port}/gateway`
	}

	let options = {
		// uid: 'gdmz', //调度台用户名
		// pwd: 'Mz3230033#', //调度台密码
		uid: 'gdmztest', //调度台用户名
		pwd: 'Mz3230033#', //调度台密码
		// localVideo: document.getElementById("localVideo2"),
		localAudio: document.getElementById(`remoteAudio2`),
		host: url.hostname,
		port: url.port,
		httpProxy: httpProxy,
		ssl: url.protocol.startsWith('https'),
		privateNet: false,
		encryType: 'v2',
		encryTime: Math.floor(Date.now() / 1000)
	}
	//正式环境
	if (process.env.VUE_APP_DEV == 'production') {
		options.uid = 'gdmz'
		options.pwd = 'Mz3230033#'
	}
	console.log('mClientToken--',mClientToken);
	// if(!mClientToken){
		//创建实例
		mClientAll = new mcs8Client()
	
		//连接网关
		mClientAll.connect(options)
	
		//注册回调
		mClientAll.on('OnManage', function (request) {
			ORGIIN = GATEWAY_URL.replace('gateway', '')
			console.log('注册回调----', request)
			onReceived(request)
		})
	// }
}

/**
 * 注册消息回调
 * @param {*} msg
 * @returns
 */
let mToken ;
export const onReceived = (msg) => {
	if (msg.method == 'ConnecteInfo') {
		//连接成功
		mToken = msg.data.token
		mClientAll.mConnected = true
		sessionStorage.setItem('mClientToken', msg.data.token)
		return
	} else if (msg.method == 'responseConnectGateway') {
		console.log('msg==', msg)
		if (msg.errCode == 200) {
			//ok
		} else if (msg.errCode == 500) {
			sessionStorage.setItem('mClientToken', '')
			console.error('error:连接服务器失败3',msg);
			// proxy.$message({ type: 'error', message: '连接服务器失败3' })
			// close()
		} else {
			sessionStorage.setItem('mClientToken', '')
			console.error('error:鉴权失败！用户名或密码错误，或无权限',msg);
			// proxy.$message({ type: 'error', message: '鉴权失败！用户名或密码错误，或无权限' })
			// close()
		}
	} else if (msg.method == 'JoinRoomAndProduct') {
		/*创建群组，调度台端一般为收到群组邀请，如设备端发起呼叫
            msg.data = {
                "roomId": "20315664", //组Id
                "TalkGroupType": 5,   //组类型，4：集群对讲组，5：视频组，6：语音组
                "creatorId": "test2", //创建者设备Id，即呼叫方
                "devList": []         //成员
            }*/
	} else if (msg.method == 'joinRoom') {
	} else if (msg.method == 'newPeer') {
		//进入组
	} else if (msg.method == 'peerClosed') {
		//退出组
	} else if (msg.method == 'noAgreeIntoGroup') {
		//拒绝进入组
	} else if (msg.method == 'meetingOut') {
		//解散组
	} else if (msg.method == 'newTalker') {
		//申请发言（成功，正在发言）
	} else if (msg.method == 'freeTalker') {
		//释放发言
	} else if (msg.method == 'gpsUpload') {
		//设备GPS消息上报，坐标类型：WGS84(大地坐标)：
	} else if (msg.method == 'DeviceStatus') {
		//设备状态消息上报，如上线、下线：
	} else if (msg.method == 'AlarmUpload') {
		//设备报警消息上报，如SOS
	} else if (msg.method == 'logoutByOtherLogin') {
		//踢出（异地登录）
		// close();
	} else {
		return
	}
}